export default function nav() {
	return {
		display: "",
		activeSection: "",
		menuOpen: false,
		mobile: false,
		init() {
			this.checkIfStuck();
			this.checkActiveSection();
			this.checkViewportSize();
			window.addEventListener("scroll", () => {
				this.checkActiveSection();
				this.checkIfStuck();
			});
			window.addEventListener("resize", () => {
				this.checkViewportSize();
			});
		},
		toggleMenu() {
			this.menuOpen = !this.menuOpen;
		},
		checkViewportSize() {
			if (window.innerWidth < 640) {
				this.mobile = true;
			} else {
				this.mobile = false;
			}
		},
		checkIfStuck() {
			const main = document.querySelector("main");
			const bufferSize = window.innerWidth > 640 ? 176 : 78;
			if (main.getBoundingClientRect().top <= 0) {
				this.display = "sticky";
			} else if (
				main.getBoundingClientRect().top <=
				window.innerHeight - bufferSize
			) {
				this.display = "absolute";
			} else {
				this.display = "fixed";
			}
		},
		checkActiveSection() {
			const sections = document.querySelectorAll("[data-section]");
			sections.forEach((section) => {
				if (
					section.getBoundingClientRect().top <=
					window.innerHeight / 2
				) {
					this.activeSection = section.getAttribute("id");
				}
			});
		},
		scrollTo(element) {
			const el = document.getElementById(element);
			const hashval = this.$el.getAttribute("href");
			el.scrollIntoView({ behavior: "smooth" });
			history.pushState(null, null, hashval);
		},
	};
}
