import { inView, animate } from "motion";

inView(".animateIn", (info) => {
	animate(
		info.target,
		{ opacity: [0, 1], y: [50, 0] },
		{
			duration: 0.6,
			delay: 0.3,
			easing: "ease-out",
		}
	);
});

inView(".fadeIn", (info) => {
	animate(
		info.target,
		{ opacity: [0, 1] },
		{
			duration: 0.6,
			delay: 0.3,
			easing: "ease-out",
		}
	);
});
