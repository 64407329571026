import Swiper from "swiper";
import "swiper/css";

export default function carousel() {
	const swiper = new Swiper(this.$refs.carousel, {
		speed: 600,
		loop: true,
		autoHeight: false,
		spaceBetween: 0,
		slidesPerView: 1,
		breakpoints: {
			640: {
				spaceBetween: -1,
				slidesPerView: 2,
			},
			768: {
				spaceBetween: -1,
				slidesPerView: 3,
			},
			1280: {
				spaceBetween: -1,
				slidesPerView: 4,
			},
		},
	});
	return {
		next() {
			swiper.slideNext();
		},
		prev() {
			swiper.slidePrev();
		},
	};
}
