export default function slideImages() {
	return {
		init() {
			const rows = this.$root.querySelectorAll("ul");

			rows.forEach((row) => {
				const direction = row.dataset.direction;
				this.slide(row, direction);
				window.addEventListener("scroll", () => {
					this.slide(row, direction);
				});
			});
		},

		slide(el, direction) {
			const root = this.$root;
			if (
				root.getBoundingClientRect().top < window.innerHeight &&
				root.getBoundingClientRect().bottom > 0 &&
				direction === "left"
			) {
				const scrollVal =
					root.getBoundingClientRect().top - window.innerHeight;
				el.style.transform = `translate3d(${scrollVal}px, 0, 0)`;
			}
			if (
				root.getBoundingClientRect().top < window.innerHeight &&
				root.getBoundingClientRect().bottom > 0 &&
				direction === "right"
			) {
				const scrollVal =
					(root.getBoundingClientRect().top - window.innerHeight) *
					-1;
				el.style.transform = `translate3d(${scrollVal}px, 0, 0)`;
			}
		},
	};
}
