export default function newsletterSignup() {
	return {
		success: false,
		submitText: "Submit",
		dataMsg: "",
		submitForm() {
			const form = this.$root;
			const formData = new FormData(form);
			this.submitText = "Submitting";
			fetch("/mailchimp/send", {
				method: "post",
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data.success) {
						form.reset();
						this.submitText = "Submitted";
						setTimeout(() => {
							this.success = true;
							this.dataMsg = data.msg;
						}, 600);
					} else {
						setTimeout(() => {
							this.dataMsg = data.msg;
							this.submitText = "Try Again";
							success = false;
						}, 600);
					}
				});
		},
	};
}
