import { inView, animate } from "motion";
export default function loadInImages() {
	return {
		init() {
			const images = this.$root.querySelectorAll("picture");
			inView(this.$root, () => {
				if (images[0]) {
					animate(
						images[0],
						{
							opacity: ["0", "1"],
							y: ["-15vh", "0"],
							x: ["-15vh", "0"],
						},
						{
							duration: 0.6,
							delay: 0.3,
							easing: "ease-out",
						}
					);
				}
				if (images[1]) {
					animate(
						images[1],
						{
							opacity: ["0", "1"],
							y: ["-15vh", "0"],
							x: ["15vh", "0"],
						},
						{
							duration: 0.6,
							delay: 0.3,
							easing: "ease-out",
						}
					);
				}
				if (images[2]) {
					animate(
						images[2],
						{
							opacity: ["0", "1"],
							y: ["15vh", "0"],
							x: ["15vh", "0"],
						},
						{
							duration: 0.6,
							delay: 0.3,
							easing: "ease-out",
						}
					);
				}
			});
		},
	};
}
