export default function cursor() {
	return {
		active: false,
		init() {
			if (window.matchMedia("(pointer: coarse)").matches) return;
			document.onmousemove = (e) => {
				this.$root.style.left = e.clientX - 10 + "px";
				this.$root.style.top = e.clientY - 10 + "px";
				if (e.target.matches("a, button, button *")) {
					this.active = true;
				} else {
					this.active = false;
				}
			};
		},
	};
}
